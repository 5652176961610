import axios from 'axios';
// import logger from 'helpers/logger';

export function getStrapiURL(path = '') {
  return `${process.env.NEXT_PUBLIC_STRAPI_API_URL || 'https://cms.aluguefoco.com.br'
    }${path}`;
}

export function getStrapiToken() {
  // logger.info(process.env.STRAPI_TOKEN);
  return process.env.STRAPI_TOKEN;
}

const api = axios.create({
  baseURL: getStrapiURL(),
  headers: getStrapiToken()
    ? {
      Authorization: `Bearer ${getStrapiToken()}`,
    }
    : {},
});

const local = axios.create({
  baseURL: process.env.RAC_URL,
});

export { api, local };
