// pages/_app.tsx
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSnapshot } from 'valtio';
import reservation_state from '@/contexts/reservation_state';
import { AppProps } from 'next/app';
import Layout from '@/components/Layout';
import { LayoutProvider } from '@/contexts/layout';
import { ReservationProvider } from '@/contexts/reservation';
import { SettingsProvider } from '@/contexts/settings';
import '@/styles/fonts.css';
import '@/styles/globals.css';
import '@/styles/form.css';
import '@/styles/adyen.css';
import 'react-loading-skeleton/dist/skeleton.css';
import '../i18n';
import ScreenViewEvent from '@/components/DataLayer/ScreenViewEvent';
import { api } from "@/utils/api";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient()

function MyApp({ Component, pageProps }: AppProps) {
  const routerInstance = useRouter();
  const snapshot = useSnapshot(reservation_state);

  useEffect(() => {
    const { query } = routerInstance;
    reservation_state.pickup_store = typeof query.pickup_store === 'string' ? query.pickup_store : undefined;
    reservation_state.pickup_date = typeof query.pickup_date === 'string' ? query.pickup_date : undefined;
    reservation_state.pickup_time = typeof query.pickup_time === 'string' ? query.pickup_time : undefined;
    reservation_state.return_store = typeof query.return_store === 'string' ? query.return_store : undefined;
    reservation_state.return_date = typeof query.return_date === 'string' ? query.return_date : undefined;
    reservation_state.return_time = typeof query.return_time === 'string' ? query.return_time : undefined;
    reservation_state.driver = typeof query.driver === 'string' ? query.driver : undefined;
    reservation_state.coupon = typeof query.coupon === 'string' ? query.coupon : undefined;
  }, [routerInstance.query]);

  useEffect(() => {
    const query: any = {};
    if (snapshot.pickup_store) query.pickup_store = snapshot.pickup_store;
    if (snapshot.pickup_date) query.pickup_date = snapshot.pickup_date;
    if (snapshot.pickup_time) query.pickup_time = snapshot.pickup_time;
    if (snapshot.return_store) query.return_store = snapshot.return_store;
    if (snapshot.return_date) query.return_date = snapshot.return_date;
    if (snapshot.return_time) query.return_time = snapshot.return_time;
    if (snapshot.driver) query.driver = snapshot.driver;
    if (snapshot.coupon) query.coupon = snapshot.coupon;

    routerInstance.replace({
      pathname: routerInstance.pathname,
      query: { ...routerInstance.query, ...query },
    }, undefined, { shallow: true });
  }, [
    snapshot.pickup_store,
    snapshot.pickup_date,
    snapshot.pickup_time,
    snapshot.return_store,
    snapshot.return_date,
    snapshot.return_time,
    snapshot.driver,
    snapshot.coupon
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <SettingsProvider>
        <ReservationProvider>
          <LayoutProvider>
            <ScreenViewEvent />
            <Layout page={routerInstance.route}>
              <Component {...pageProps} />
            </Layout>
          </LayoutProvider>
        </ReservationProvider>
      </SettingsProvider>
    </QueryClientProvider>
  );
}

export default api.withTRPC(MyApp);
