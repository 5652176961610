import Head from 'next/head';
import Image from 'next/image';
import {
  MouseEventHandler,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import ReservationBar from 'components/ReservationBar';
import { useLayout } from 'contexts/layout';
import { ReservationBarProvider } from 'contexts/reservationBar';
import { AnimatePresence, motion } from 'framer-motion';
import styles from 'styles/Layout/Layout.module.css';

import { local } from '@/api/api';
import { MenuType } from '@/types';
import Navbar from './Navbar';

export type LayoutProps = PropsWithChildren & { page: string };

const variants = {
  hidden: { opacity: 0, x: -200, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 0, y: -100 },
};

const override = {
  display: 'block',
  borderColor: 'var(--primary)',
};

export default function Layout({ children, page }: LayoutProps) {
  const { reservationNavMode } = useLayout();

  const [menus, setMenus] = useState<MenuType[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetch() {
      try {
        const {
          data: { menus },
        } = await local('/api/settings');
        setMenus(menus);
      } catch (err) {
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 700);
      }
    }

    fetch();
  }, []);

  const close = () => {
    setIsOpen(false);
  };

  const toggle: MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Head>
        <title>
          Configurar reserva / Não esqueça de reservar
        </title>
        <meta
          name="description"
          content="Descontos Na Sua Reserva: Até 40% OFF aplicada na sua tarifa. Confira!"
        />

        <meta
          name="tags"
          content={
            process.env.NEXT_PUBLIC_HEAD_KEYWORDS ||
            'Alugue Foco, Aplicativos, Motorista de Aplicativos, Reserva, Aluguel de Carros'
          }
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className={`${styles.layout} ${reservationNavMode === 'floater' ? styles.layout_floater : ''}`}>
        <AnimatePresence mode="wait">
          {isLoading ? (
            <motion.div
              key="loading"
              className={styles.loading}
              initial={{ opacity: 0, scale: 0.5 }}
              exit={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <div>
                <Image
                  priority
                  layout="fixed"
                  width={154}
                  height={70}
                  src="/img/logo.png"
                  alt="Alugue Foco"
                />
              </div>
              <div className={styles.loading__content}>
                <ClipLoader
                  color="#f20000"
                  loading={isLoading}
                  cssOverride={override}
                  size={30}
                />
                <p>Carregando...</p>
              </div>
            </motion.div>
          ) : (
            <motion.div
              key="content"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <Navbar
                isOpen={isOpen}
                close={close}
                toggle={toggle}
                menus={menus}
                setMenus={setMenus}
              />
              <div onClick={close} className={`${styles.main__container} ${reservationNavMode === 'floater' ? styles.main__container_floater : ''}`}>


                <ReservationBarProvider>
                  <div className={styles.reservationBar__container}>
                    <ReservationBar />
                  </div>
                  <div className={styles.cards__container}>
                    <motion.div
                      key={page}
                      variants={variants}
                      initial="hidden"
                      animate="enter"
                      exit="exit"
                      transition={{ type: 'linear' }}
                    >
                      {children}
                    </motion.div>
                  </div>

                </ReservationBarProvider>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
}
