// components/ScreenViewEvent.tsx

import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

const ScreenViewEvent: React.FC = () => {
  const router = useRouter();
  const referral = useRef();

  type ScreenData = {
    [key: string]: { pageType: string; pageTitle: string } | undefined;
  };

  const screenData: ScreenData = {
    '/': { pageType: 'itinerary', pageTitle: 'Itinerário' },
    '/veiculos': { pageType: 'vehicles', pageTitle: 'Veículos' },
    '/extras': { pageType: 'optionals', pageTitle: 'Extras' },
    '/condutor': { pageType: 'checkout', pageTitle: 'Condutor' },
    '/reserva': { pageType: 'poscheckout', pageTitle: 'Reserva' },
  };

  const getScreenViewData = (url: string): { pageType: string; pageTitle: string } => {
    const path = url.split('?')[0] || '';
    const route = path.split('/').pop() || '';
    return screenData[`/${route}`] || { pageType: 'other', pageTitle: 'Outro' };
  };

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const { pageType, pageTitle } = getScreenViewData(url);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'screen_view',
        pageType,
        pageTitle,
        pageUrl: url,
        pageReferral: referral.current || '',
        userLanguage: navigator.language,
        userRegion: '',
        userCity: ''
      });
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    handleRouteChange(window.location.href);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return null;
};

export default ScreenViewEvent;
