// src/contexts/reservation_state.ts
import { EstimateOptionalType } from '@/helpers/optionals';
import { DepotType, OptionalType, VehicleType } from '@/types';
import { Car, Estimate, Itinerary, Rate, RateDetail } from '@/types/coral-types';
import { proxy } from 'valtio';
import { ItineraryType } from './reservation';

export interface ReservationContextInterface {
  itinerary?: Itinerary;
  itineraryReservation?: ItineraryType;
  rate?: RateDetail;
  rates?: Rate;
  optionals?: EstimateOptionalType[];
  renter?: any;
  estimate?: Estimate;
  setEstimate: (estimate: Estimate) => void;
  depot?: DepotType;
  returnDepot?: DepotType;
  vehicle?: Car;
  vehicleReservation?: VehicleType;
  returnDepotReservation?: DepotType;
  vehicles?: Car[];
  isPrePaid: boolean;
  paymentMethod: 'pix' | 'credit_card' | 'pos_paid';
  reservation: any;
  setReservation: (reservation: any) => void;
  setPaymentMethod: (method: 'pix' | 'credit_card' | 'pos_paid') => void;
  user: any;
  setUser: (user: any) => void;
  reset: () => void;
  cpdid: string;
  pickup_store?: string;
  pickup_date?: string;
  pickup_time?: string;
  return_store?: string;
  return_date?: string;
  return_time?: string;
  driver?: string;
  coupon?: string;
  allOptionals?: OptionalType[];
}

const reservation_state = proxy<ReservationContextInterface>({
  isPrePaid: false,
  paymentMethod: 'credit_card',
  reset: () => {
    reservation_state.itinerary = undefined;
    reservation_state.rate = undefined;
    reservation_state.rates = undefined;
    reservation_state.optionals = undefined;
    reservation_state.renter = undefined;
    reservation_state.estimate = undefined;
    reservation_state.depot = undefined;
    reservation_state.returnDepot = undefined;
    reservation_state.vehicle = undefined;
    reservation_state.vehicles = undefined;
    reservation_state.reservation = {};
    reservation_state.user = {};
    reservation_state.cpdid = '';
    reservation_state.pickup_store = undefined;
    reservation_state.pickup_date = undefined;
    reservation_state.pickup_time = undefined;
    reservation_state.return_store = undefined;
    reservation_state.return_date = undefined;
    reservation_state.return_time = undefined;
    reservation_state.driver = undefined;
    reservation_state.coupon = undefined;
    reservation_state.allOptionals = undefined;
  },
  cpdid: '',
  setEstimate: (estimate: Estimate) => {
    reservation_state.estimate = estimate;
  },
  reservation: {},
  setReservation: (reservation: any) => {
    reservation_state.reservation = reservation;
  },
  setPaymentMethod: (method: 'pix' | 'credit_card' | 'pos_paid') => {
    reservation_state.paymentMethod = method;
  },
  user: {},
  setUser: (user: any) => {
    reservation_state.user = user;
  },
  pickup_store: undefined,
  pickup_date: undefined,
  pickup_time: undefined,
  return_store: undefined,
  return_date: undefined,
  return_time: undefined,
  driver: undefined,
  coupon: undefined,
});

export default reservation_state;
